import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import Container from 'react-bootstrap/Container';
import HTMLReactParser from 'html-react-parser';

const PAGE = gql`
query getPageContent {
  post(id:5) {
  data {
    id,
    attributes {
      title,
    page_id,
    subtitle1,
    subtitle2,
    body
    }
  }
  }
}
`

export default function ParticipationAgreement() {
  const { loading, error, data } = useQuery(PAGE)

  if (loading) return
  if (error) return

  const CONTENT = data.post.data.attributes
  // console.log(CONTENT)

  return (
    <div>
      <Container className='contentWrap' style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
        <span className='breadcrumbs' style={{ color: '#003366' }}><Link to='/' style={{ textDecoration: 'none', color: '#003366' }}>Home</Link> &#62; <b>Participation Agreement</b></span>
        <div className="pt-5" style={{ display: 'flex', flexDirection: 'column' }}>
          <h3 style={{ textAlign: 'center', color: '#003366' }}><b>{CONTENT.title}</b></h3>
          <h5 style={{ textAlign: 'center', color: '#003366' }}>
            {CONTENT.subtitle1.slice(0, 45)}
            <b>{CONTENT.subtitle1.slice(45, 54)}</b>
            {CONTENT.subtitle1.slice(54)}
          </h5>
          <h5 style={{ textAlign: 'center', color: '#003366' }}>
            {CONTENT.subtitle2.slice(0, 30)}
            <b>{CONTENT.subtitle2.slice(30, 39)}</b>
            {CONTENT.subtitle2.slice(39)}
          </h5>
          <br />
          <div>
            {HTMLReactParser(CONTENT.body)}

          </div>


        </div>





      </Container>
    </div>
  )
}
