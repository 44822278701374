import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import NavLink from 'react-bootstrap/NavLink';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Desktop, Tablet, Mobile, Default, NotPC } from './MediaQuery'

const EXPAND = 'lg';
const EMPTY_STYLE = { color: 'white', textDecoration: 'none' }

export default function Navigation() {
  const [show, setShow] = useState(false);
  const offsetValue = -56;
  const toggleOffCanvas = () => {
    setShow((show) => !show);
  };
  const toggleOffCanvas2 = () => {
    setShow((show) => !show);
  };
  const handleClose = () => setShow(false);

  const [showDropdown, setShowDropdown] = useState(false);
  const setDropdown = () => {
    setShowDropdown((show) => !showDropdown);
  }
  const [showDropdown2, setShowDropdown2] = useState(false);
  const setDropdown2 = () => {
    setShowDropdown2((show) => !showDropdown2);
  }

  return (
    <>
      <Container fluid className='headerBar'>
        <Container style={{ maxWidth: '1200px' }}>
          <a href='https://cbsa.hkust.edu.hk' className='headerElement' target="_blank" rel="noopener noreferrer">
            <Desktop>Center for Business and Social Analytics </Desktop>
            <Tablet>Center for Business and Social Analytics </Tablet>
            <Mobile>CBSA </Mobile>
            <img src="/assets/icons/external-link.png" />
          </a>
          <a style={{ paddingLeft: '10px' }} href='https://www.linkedin.com/company/hkust-center-for-business-and-social-analytics-cbsa/'
            className='headerElement' target="_blank" rel="noopener noreferrer">
            <Desktop>Follow us on </Desktop>
            <img src='/assets/icons/linkedin.png' />
          </a>
        </Container>
      </Container>

      <Navbar sticky='top' key={EXPAND} bg="white" expand={EXPAND} style={{ boxShadow: '0 0 12px rgb(0 0 0 / 40%)' }}>
        <Container style={{ maxWidth: '1200px' }}>
          <Navbar.Brand className='mainlogo'>
            <a href="https://hkust.edu.hk" target="_blank" rel="noopener noreferrer">
              <img className='hkustlogo' src="/assets/logos/mainlogo.svg" style={{ paddingRight: '12px', borderRight: '1px solid #003366', minWidth: '50px', maxWidth: '200px', width: '40%' }} />
            </a>
            <Link to='/' className='challengelogo' style={{ paddingLeft: '12px', textDecoration: 'none', color: '#003366' }}>
              <span>ANALYTICS CHALLENGE</span>
            </Link>

          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${EXPAND}`} onClick={toggleOffCanvas} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${EXPAND}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${EXPAND}`}
            placement="end"
            show={show}
            backdrop={true}
            onHide={handleClose}
          >
            <Offcanvas.Header closeButton onClick={toggleOffCanvas} onHide={toggleOffCanvas}>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${EXPAND}`}>
                {/* Analytics Challenge 2022 */}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3" >
                <Desktop >
                  <Nav.Link style={{ color: '#003366', backgroundColor: '#FFDE59', fontWeight: 700, width: '140px', textAlign: 'center' }} as={Link} to="/Sponsorship">BE A SPONSOR</Nav.Link>
                  <Nav.Link style={{}} as={Link} to="/">HOME</Nav.Link>
                  <NavDropdown
                    title="ABOUT"
                    id={`offcanvasNavbarDropdown-expand-${EXPAND}`}
                    className="border-0"
                    show={showDropdown}
                    onToggle={setDropdown}
                  >
                    <Nav.Link style={{ padding: "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)" }} as={Link} to="/About/About-Us" onClick={setDropdown}>
                      About Us
                    </Nav.Link>
                    <Nav.Link style={{ padding: "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)" }} as={Link} to="/About/Our-People" onClick={setDropdown}>
                      Our People
                    </Nav.Link>
                    <Nav.Link style={{ padding: "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)" }} as={Link} to="/About/Past-Finalists/2022" onClick={setDropdown}>
                      2022 Finalists
                    </Nav.Link>
                  </NavDropdown>
                  <NavDropdown
                    title="RESOURCES"
                    id={`offcanvasNavbarDropdown-expand-${EXPAND}`}
                    className="border-0"
                    show={showDropdown2}
                    onToggle={setDropdown2}
                  >
                    <Nav.Link style={{ padding: "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)" }} as={Link} to="/Resources/Webinar-Series" onClick={setDropdown2}>
                      Webinar Series
                    </Nav.Link>
                    <Nav.Link style={{ padding: "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)" }} as={Link} to="/Resources/Data-Tools" onClick={setDropdown2}>
                      Data Tools
                    </Nav.Link>
                    <NavDropdown.Item style={{ padding: "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)" }} href="/Resources/Challenge-Brief-2023-2024" onClick={setDropdown2}>
                      Challenge Brief
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link style={{}} as={Link} to="/Registration">REGISTRATION</Nav.Link>
                </Desktop>

                <NotPC>
                  <Nav.Link style={{}} as={Link} to="/" onClick={toggleOffCanvas}>HOME</Nav.Link>
                  {/* <Nav.Link style={{}} as={Link} to="/About" onClick={toggleOffCanvas}>ABOUT</Nav.Link> */}
                  <NavDropdown
                    title="ABOUT"
                    id={`offcanvasNavbarDropdown-expand-${EXPAND}`}
                    className="border-0">
                    <Nav.Link style={{ padding: "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)" }} as={Link} to="/About/About-Us" onClick={toggleOffCanvas}>
                      About Us
                    </Nav.Link>
                    <Nav.Link style={{ padding: "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)" }} as={Link} to="/About/Our-People" onClick={toggleOffCanvas}>
                      Our People
                    </Nav.Link>
                    <Nav.Link style={{ padding: "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)" }} as={Link} to="/About/Past-Finalists/2022" onClick={toggleOffCanvas}>
                      2022 Finalists
                    </Nav.Link>
                  </NavDropdown>
                  <NavDropdown
                    title="RESOURCES"
                    id={`offcanvasNavbarDropdown-expand-${EXPAND}`}
                    className="border-0">
                    <Nav.Link style={{ padding: "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)" }} as={Link} to="/Resources/Webinar-Series" onClick={toggleOffCanvas2}>
                      Webinar Series
                    </Nav.Link>
                    <Nav.Link style={{ padding: "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)" }} as={Link} to="/Resources/Data-Tools" onClick={toggleOffCanvas2}>
                      Data Tools
                    </Nav.Link>
                    <NavDropdown.Item style={{ padding: "var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x)" }} href="/Resources/Challenge-Brief-2023-2024" onClick={toggleOffCanvas2}>
                      Challenge Brief
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link style={{}} as={Link} to="/Registration" onClick={toggleOffCanvas}>REGISTRATION</Nav.Link>
                  <Nav.Link as={Link} to="/Sponsorship" onClick={toggleOffCanvas}><span style={{ color: '#003366', backgroundColor: '#FFDE59', fontWeight: 700, padding: '7px 10px' }}>BE A SPONSOR</span></Nav.Link>

                </NotPC>

              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  )
}
