import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import Container from 'react-bootstrap/Container';
import HTMLReactParser from 'html-react-parser';

import { Desktop, Tablet, Mobile, Default } from '../../components/MediaQuery';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import groupphoto from '../../img/winnerAnnouncement/AC2022/AC2022-23_groupphoto.jpg';
import teamx from '../../img/winnerAnnouncement/AC2022/teamx.jpg';
import nlpower from '../../img/winnerAnnouncement/AC2022/nlpower.jpg';
import esglytic from '../../img/winnerAnnouncement/AC2022/esglytic.jpg';
import greenconda from '../../img/winnerAnnouncement/AC2022/greenconda.jpg';
import greenist from '../../img/winnerAnnouncement/AC2022/greenist.jpg';
import images from '../../img/carousel/ac2022/index';
import AliceCarousel from 'react-alice-carousel';

const winnerMap = { 'teamx': teamx, 'nlpower': nlpower, 'esglytic': esglytic, 'greenconda': greenconda, 'greenist': greenist }

const handleDragStart = (e) => e.preventDefault();

const carouselItems = [];
for (let i = 0; i < images.length; i++) {
  carouselItems.push(
    <LazyLoadImage className="carousel_img" src={images[i]} onDragStart={handleDragStart} role="presentation" effect="blur" />
  );
}

const Gallery = () => {
  return (
    <div style={{ marginTop: '70px' }}>
      <AliceCarousel
        disableSlideInfo={false}
        mouseTracking items={carouselItems}
        autoPlay={true}
        autoPlayInterval={3000}
        infinite={true}
        autoPlayControls={true}
        responsive={{
          0: {
            items: 2,
            itemsFit: 'contain'
          },
          767: {
            items: 3,
            itemsFit: 'contain'
          }
        }}
      />
    </div>

  );
}


const PAGE = gql`
query getPageContent {
  page(id:17) {
    data {
      id,
      attributes {
        page_id,
        content 
      }
    }
  }
}
`

export default function Finalists2022() {
  const { loading, error, data } = useQuery(PAGE)

  if (loading) return
  if (error) return

  const CONTENT = data.page.data.attributes.content
  // console.log(SUPPORT)

  return (
    <div>
      <Desktop>
        <section className="winnerAnnouncement pb-5">
          <Container style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
            <span className='breadcrumbs' style={{ color: '#003366', marginBottom: '50px' }}><Link to='/' style={{ textDecoration: 'none', color: '#003366' }}>Home</Link> &#62; About &#62; Past Finalists &#62; <b>2022</b></span>
            <span style={{ fontSize: '38px', fontWeight: '500', textAlign: 'center' }}>{CONTENT.winnerAnnouncement.title}</span>
            <span style={{ marginBottom: '50px', fontSize: '20px', textAlign: 'center' }}>{CONTENT.winnerAnnouncement.subtitle}</span>

            <div style={{ backgroundColor: '#F7F7F7', padding: '30px 50px' }}>
              <p style={{ marginBottom: '20px' }}>{CONTENT.winnerAnnouncement.content.intro}</p>
              <LazyLoadImage src={groupphoto} style={{ marginBottom: '20px', width: "100%" }} alt="Analytics Challenge group photo" effect="blur" />
              <p style={{ width: '100%' }}>{CONTENT.winnerAnnouncement.content.intro2}</p>

              <div style={{ display: 'flex', gap: '50px', margin: '30px 0' }}>
                <div style={{ width: '100%' }}>
                  <span style={{ fontSize: '25px' }}><b>{CONTENT.winnerAnnouncement.content.winners.title}</b></span>
                  {CONTENT.winnerAnnouncement.content.winners.list.map((winner, i) => (
                    <div key={i} style={{ display: 'flex', flexDirection: 'row', margin: '10px 0 30px', gap: '10px' }} >
                      <div style={{ width: '30%' }}>
                        <LazyLoadImage
                          effect="blur"
                          alt="Analytics Challenge winners and finalists photos"
                          src={winnerMap[winner.teamid]}
                          style={{ width: '100%' }}
                        />
                      </div>
                      <div style={{ padding: '0 10px', width: '70%' }}>
                        {winner.details.map((detail, idx) => (
                          <div key={idx}>
                            <span >{HTMLReactParser(detail)}</span><br />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Container>
        </section>
        <section className="carousel pt-5 pb-5" >
          <Container style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ color: '#003366', fontSize: '38px', fontWeight: '700', textAlign: 'left' }}>{CONTENT.carousel.title}</span>
              <span style={{ width: '45%', alignSelf: 'center' }}>{CONTENT.carousel.content}</span>
            </div>
            <div style={{}}>
              <Gallery />
            </div>
          </Container>
        </section>
      </Desktop>

      <Tablet>
        <section className="winnerAnnouncement pb-5">
          <Container style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column' }}>
            <span className='breadcrumbs' style={{ color: '#003366', marginBottom: '50px' }}><Link to='/' style={{ textDecoration: 'none', color: '#003366' }}>Home</Link> &#62; About &#62; Past Finalists &#62; <b>2022</b></span>
            <span style={{ fontSize: '38px', fontWeight: '500', textAlign: 'center' }}>{CONTENT.winnerAnnouncement.title}</span>
            <span style={{ marginBottom: '50px', fontSize: '20px', textAlign: 'left' }}>{CONTENT.winnerAnnouncement.subtitle}</span>
            <div style={{ backgroundColor: '#F7F7F7', padding: '20px 30px' }}>
              <p style={{ marginBottom: '20px' }}>{CONTENT.winnerAnnouncement.content.intro}</p>
              <LazyLoadImage src={groupphoto} style={{ marginBottom: '20px', width: "100%" }} alt="Analytics Challenge group photo" effect="blur" />
              <p style={{ width: '100%' }}>{CONTENT.winnerAnnouncement.content.intro2}</p>

              <div style={{ display: 'flex', gap: '50px', margin: '30px 0' }}>
                <div style={{ width: '100%' }}>
                  <span style={{ fontSize: '25px' }}><b>{CONTENT.winnerAnnouncement.content.winners.title}</b></span>
                  {CONTENT.winnerAnnouncement.content.winners.list.map((winner, i) => (
                    <div key={i} style={{ display: 'flex', flexDirection: 'row', margin: '10px 0 30px', gap: '10px' }} >
                      <div style={{ width: '30%' }}>
                        <LazyLoadImage
                          effect="blur"
                          alt="Analytics Challenge winners and finalists photos"
                          src={winnerMap[winner.teamid]}
                          style={{ width: '100%' }}
                        />
                      </div>
                      <div style={{ padding: '0 10px', width: '70%' }}>
                        {winner.details.map((detail, idx) => (
                          <div key={idx}>
                            <span >{HTMLReactParser(detail)}</span><br />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Container>
        </section>
        <section className="carousel pt-5 pb-5" >
          <Container style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ color: '#003366', fontSize: '38px', fontWeight: '700', textAlign: 'left' }}>{CONTENT.carousel.title}</span>
              <span style={{ width: '45%', alignSelf: 'center' }}>{CONTENT.carousel.content}</span>

            </div>
            <div style={{}}>
              <Gallery />
            </div>

          </Container>
        </section>
      </Tablet>


      <Mobile>
        <section className="winnerAnnouncement pb-5">
          <Container style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column' }}>
            <span className='breadcrumbs' style={{ color: '#003366', marginBottom: '20px' }}><Link to='/' style={{ textDecoration: 'none', color: '#003366' }}>Home</Link> &#62; About &#62; Past Finalists &#62; <b>2022</b></span>
            <span style={{ fontSize: '26px', fontWeight: '500', textAlign: 'center' }}>{CONTENT.winnerAnnouncement.title}</span>
            <span style={{ marginBottom: '20px', fontSize: '16px', textAlign: 'left' }}>{CONTENT.winnerAnnouncement.subtitle}</span>
            <div style={{ backgroundColor: '#F7F7F7', padding: '20px' }}>
              <p style={{ marginBottom: '20px' }}>{CONTENT.winnerAnnouncement.content.intro}</p>
              <LazyLoadImage src={groupphoto} style={{ marginBottom: '20px', width: "100%" }} alt="Analytics Challenge group photo" effect="blur" />
              <p style={{ width: '100%' }}>{CONTENT.winnerAnnouncement.content.intro2}</p>

              <div style={{ display: 'flex', gap: '50px', margin: '30px 0' }}>
                <div style={{ width: '100%' }}>
                  <span style={{ fontSize: '25px' }}><b>{CONTENT.winnerAnnouncement.content.winners.title}</b></span>
                  {CONTENT.winnerAnnouncement.content.winners.list.map((winner, i) => (
                    <div key={i} style={{ display: 'flex', flexDirection: 'column', margin: '10px 0 30px', gap: '10px' }} >
                      <div>
                        <LazyLoadImage
                          effect="blur"
                          alt="Analytics Challenge winners and finalists photos"
                          src={winnerMap[winner.teamid]}
                          style={{ width: '100%' }}
                        />
                      </div>
                      <div style={{ padding: '0 10px' }}>
                        {winner.details.map((detail, idx) => (
                          <div key={idx}>
                            <span >{HTMLReactParser(detail)}</span><br />
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Container>
        </section>
        <section className="carousel pt-3 pb-3">
          <Container style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#003366', fontSize: '22px', fontWeight: '700', textAlign: 'left' }}>{CONTENT.carousel.title}</span>
              <span style={{ alignSelf: 'center', fontSize: '14px' }}>{CONTENT.carousel.content}</span>

            </div>
            <Gallery />

          </Container>
        </section>
      </Mobile>
      <Container className='contentWrap' />

    </div>

  )
}