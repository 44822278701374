import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import Container from 'react-bootstrap/Container';
import { Desktop, Tablet, Mobile, Default } from '../components/MediaQuery'

const PAGE = gql`
query getPageContent {
  page(id:15) {
  data {
    id,
    attributes {
    page_id,
    content
    }
  }
  }
}
`

export default function Registration() {
  const { loading, error, data } = useQuery(PAGE)

  if (loading) return
  if (error) return

  // console.log(data.page.data.attributes)
  const CONTENT = data.page.data.attributes.content.registration

  return (
    <div>
      <Container className='contentWrap' style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
        <span className='breadcrumbs' style={{ color: '#003366' }}><Link to='/' style={{ textDecoration: 'none', color: '#003366' }}>Home</Link> &#62; <b>Registration</b></span>
        <div className="pt-5" style={{ display: 'flex', flexDirection: 'column' }}>
          <img style={{ width: '100%' }} src="/assets/images/registration.jpg" />
          <div className="registration mt-3 pb-5" style={{ color: '#003366', textAlign: "center" }}>
            <Default>
              <span className="fs-1"><b>{CONTENT.title}</b></span>
              <div style={{ position: 'relative' }}>
                <img src="/assets/images/reg_timeline.png" style={{ width: '100%' }} ></img>
                <div style={{
                  position: "absolute",
                  top: '41%',
                  left: '7.5%',
                  fontSize: 'min(1.3vw, 16px)'
                }}>
                  <span><a href={CONTENT.links.button1.link}><button style={{ color: 'white', backgroundColor: '#003366' }}>{CONTENT.links.button1.button}</button></a></span><br />
                </div>
                <div style={{
                  position: "absolute",
                  top: '41%',
                  left: '32%',
                  fontSize: 'min(1.3vw, 16px)'
                }}>
                  <span><a href={CONTENT.links.button2.link}><button style={{ color: 'white', backgroundColor: '#003366' }}>{CONTENT.links.button2.button}</button></a></span><br />
                </div>
                {/* <div style={{
                  position: "absolute",
                  top: '41%',
                  left: '79%',
                  fontSize: 'min(1.3vw, 16px)'
                }}>
                  <span><a href={CONTENT.links.button4.link}><button style={{ color: 'white', backgroundColor: '#003366' }}>{CONTENT.links.button4.button}</button></a></span><br />
                </div> */}
                <div style={{
                  position: "absolute",
                  top: '90.5%',
                  left: '9%',
                  fontSize: 'min(1.3vw, 16px)'
                }}>
                  <span><a href={CONTENT.links.button5.link}><button style={{ color: 'white', backgroundColor: '#003366' }}>{CONTENT.links.button5.button}</button></a></span><br />
                </div>
              </div>

            </Default>
            <Mobile>
              <span className="fs-4"><b>{CONTENT.title}</b></span>
              <div style={{ padding: '25px', display: 'flex', flexFlow: 'wrap', justifyContent: 'center' }}>
                <div style={{ backgroundColor: 'rgba(229, 229, 229, 0.3)', margin: '0 10px 20px 10px', padding: '20px', width: '300px', position: 'relative' }}>
                  <img style={{ width: '100%', paddingBottom: '20px' }} src="/assets/images/reg_timeline_mobile_1.png"></img>
                  <span><a href={CONTENT.links.button1.link}><button style={{ color: 'white', backgroundColor: '#003366', }}>{CONTENT.links.button1.button}</button></a></span><br />
                </div>
                <div style={{ backgroundColor: 'rgba(229, 229, 229, 0.3)', margin: '0 10px 20px 10px', padding: '20px', width: '300px', position: 'relative' }}>
                  <img style={{ width: '100%', paddingBottom: '20px' }} src="/assets/images/reg_timeline_mobile_2.png"></img>
                  <span><a href={CONTENT.links.button2.link}><button style={{ color: 'white', backgroundColor: '#003366', }}>{CONTENT.links.button2.button}</button></a></span><br />
                </div>
                <div style={{ backgroundColor: 'rgba(229, 229, 229, 0.3)', margin: '0 10px 20px 10px', padding: '20px', width: '300px' }}>
                  <img style={{ width: '100%' }} src="/assets/images/reg_timeline_mobile_3.png"></img>
                </div>
                <div style={{ backgroundColor: 'rgba(229, 229, 229, 0.3)', margin: '0 10px 20px 10px', padding: '20px', width: '300px', position: 'relative' }}>
                  <img style={{ width: '100%', paddingBottom: '20px' }} src="/assets/images/reg_timeline_mobile_4.png"></img>
                  {/* <span><a href={CONTENT.links.button4.link}><button style={{ color: 'white', backgroundColor: '#003366', }}>{CONTENT.links.button4.button}</button></a></span><br /> */}
                </div>
                <div style={{ backgroundColor: 'rgba(229, 229, 229, 0.3)', margin: '0 10px 20px 10px', padding: '20px', width: '300px', position: 'relative' }}>
                  <img style={{ width: '100%', paddingBottom: '20px' }} src="/assets/images/reg_timeline_mobile_5.png"></img>
                  <span><a href={CONTENT.links.button5.link}><button style={{ color: 'white', backgroundColor: '#003366', }}>{CONTENT.links.button5.button}</button></a></span><br />
                </div>
                <div style={{ backgroundColor: 'rgba(229, 229, 229, 0.3)', margin: '0 10px 20px 10px', padding: '20px', width: '300px' }}>
                  <img style={{ width: '100%' }} src="/assets/images/reg_timeline_mobile_6.png"></img>
                </div>
                <div style={{ backgroundColor: 'rgba(229, 229, 229, 0.3)', margin: '0 10px 20px 10px', padding: '20px', width: '300px' }}>
                  <img style={{ width: '100%' }} src="/assets/images/reg_timeline_mobile_7.png"></img>
                </div>
                <div style={{ backgroundColor: 'rgba(229, 229, 229, 0.3)', margin: '0 10px 20px 10px', padding: '20px', width: '300px' }}>
                  <img style={{ width: '100%' }} src="/assets/images/reg_timeline_mobile_8.png"></img>
                </div>
              </div>




            </Mobile>
            <span>{CONTENT.content}</span>
          </div>
        </div>


      </Container >
    </div >
  )
}
