import img1AC2023 from "./photos/1.jpg"
import img2AC2023 from "./photos/2.jpg"
import img3AC2023 from "./photos/3.jpg"
import img4AC2023 from "./photos/4.jpg"
import img5AC2023 from "./photos/5.jpg"
import img6AC2023 from "./photos/6.jpg"
import img7AC2023 from "./photos/7.jpg"
import img8AC2023 from "./photos/8.jpg"
import img9AC2023 from "./photos/9.jpg"
import img10AC2023 from "./photos/10.jpg"
import img11AC2023 from "./photos/11.jpg"
import img12AC2023 from "./photos/12.jpg"
import img13AC2023 from "./photos/13.jpg"
import img14AC2023 from "./photos/14.jpg"
import img15AC2023 from "./photos/15.jpg"
import img16AC2023 from "./photos/16.jpg"
import img17AC2023 from "./photos/17.jpg"
import img18AC2023 from "./photos/18.jpg"
import img19AC2023 from "./photos/19.jpg"
import img20AC2023 from "./photos/20.jpg"
import img21AC2023 from "./photos/21.jpg"
import img22AC2023 from "./photos/22.jpg"
import img23AC2023 from "./photos/23.jpg"
import img24AC2023 from "./photos/24.jpg"
import img25AC2023 from "./photos/25.jpg"
import img26AC2023 from "./photos/26.jpg"
import img27AC2023 from "./photos/27.jpg"
import img28AC2023 from "./photos/28.jpg"
import img29AC2023 from "./photos/29.jpg"
import img30AC2023 from "./photos/30.jpg"
import img31AC2023 from "./photos/31.jpg"
import img32AC2023 from "./photos/32.jpg"
import img33AC2023 from "./photos/33.jpg"
import img34AC2023 from "./photos/34.jpg"
import img35AC2023 from "./photos/35.jpg"
import img36AC2023 from "./photos/36.jpg"
import img37AC2023 from "./photos/37.jpg"
import img38AC2023 from "./photos/38.jpg"
import img39AC2023 from "./photos/39.jpg"
import img40AC2023 from "./photos/40.jpg"
import img41AC2023 from "./photos/41.jpg"
import img42AC2023 from "./photos/42.jpg"
import img43AC2023 from "./photos/43.jpg"
import img44AC2023 from "./photos/44.jpg"
import img45AC2023 from "./photos/45.jpg"

export default [
    img1AC2023,
    img2AC2023,
    img3AC2023,
    img4AC2023,
    img5AC2023,
    img6AC2023,
    img7AC2023,
    img8AC2023,
    img9AC2023,
    img10AC2023,
    img11AC2023,
    img12AC2023,
    img13AC2023,
    img14AC2023,
    img15AC2023,
    img16AC2023,
    img17AC2023,
    img18AC2023,
    img19AC2023,
    img20AC2023,
    img21AC2023,
    img22AC2023,
    img23AC2023,
    img24AC2023,
    img25AC2023,
    img26AC2023,
    img27AC2023,
    img28AC2023,
    img29AC2023,
    img30AC2023,
    img31AC2023,
    img32AC2023,
    img33AC2023,
    img34AC2023,
    img35AC2023,
    img36AC2023,
    img37AC2023,
    img38AC2023,
    img39AC2023,
    img40AC2023,
    img41AC2023,
    img42AC2023,
    img43AC2023,
    img44AC2023,
    img45AC2023,
];

