import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import NavLink from 'react-bootstrap/NavLink';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Desktop, Tablet, Mobile, Default } from './MediaQuery'


export default function Footer() {
  return (
    <>
      <Desktop>
        <Container fluid className='footer' style={{ height: '100px' }}>
          <Container className='footerContainer' style={{ fontSize: '16px', maxWidth: '1200px' }}>
            <a href='https://hkust.edu.hk' className="pe-3" target="_blank" rel="noopener noreferrer">
              <img src="/assets/logos/footerlogo.svg" />
            </a>
            <div className='pe-3'>
              <div>
                <a href='https://dataprivacy.ust.hk/university-data-privacy-policy-statement/'
                  className='fw-bold pe-3' target="_blank" rel="noopener noreferrer">Privacy</a>
                {/* <Link to='/' className='fw-bold'>Sitemap</Link> */}
              </div>
              <span>Copyright © The Hong Kong University of Science and Technology. </span>
              <span>All rights reserved.</span>
            </div>
            <div style={{ minWidth: '206px' }}>
              <span>Follow HKUST on</span>
              <Nav className="justify-content-end pe-3" style={{ float: 'left' }}>
                <Nav.Link href='https://www.facebook.com/hkust' target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', width: '30px', height: '30px', margin: '0 5px', overflow: 'hidden', position: 'relative' }}>
                  <img src='/assets/icons/fb-logo.svg' className='p-0' style={{ position: 'absolute', top: '0', left: '0', width: 'inherit', height: 'inherit' }} />
                </Nav.Link>
                <Nav.Link href='https://www.linkedin.com/school/hkust' target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', width: '30px', height: '30px', margin: '0 5px', overflow: 'hidden', position: 'relative' }}>
                  <img src='/assets/icons/li-logo.svg' style={{ position: 'absolute', top: '0', left: '0', width: 'inherit', height: 'inherit' }} />
                </Nav.Link>
                <Nav.Link href='https://www.instagram.com/hkust/' target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', width: '30px', height: '30px', margin: '0 5px', overflow: 'hidden', position: 'relative' }}>
                  <img src='/assets/icons/ig-logo.svg' style={{ position: 'absolute', top: '0', left: '0', width: 'inherit', height: 'inherit' }} />
                </Nav.Link>
                <Nav.Link href='https://www.youtube.com/user/HKUST' target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', width: '30px', height: '30px', margin: '0 5px', overflow: 'hidden', position: 'relative' }}>
                  <img src='/assets/icons/yt-logo.svg' style={{ position: 'absolute', top: '0', left: '0', width: 'inherit', height: 'inherit' }} />
                </Nav.Link>
                <Nav.Link href='https://hkust.edu.hk/sites/default/files/menu_icons/mainsite_Wechat_Eng_new.jpg' target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', width: '30px', height: '30px', margin: '0 5px', overflow: 'hidden', position: 'relative' }}>
                  <img src='/assets/icons/wechat-logo.png' style={{ position: 'absolute', top: '0', left: '0', width: 'inherit', height: 'inherit' }} />
                </Nav.Link>
              </Nav>
            </div>
          </Container>
        </Container>
      </Desktop>
      <Tablet>
        <Container fluid className='footer' style={{ height: '150px' }}>
          <div className='footerContainer' style={{ fontSize: '14px', padding: '0 30px' }}>
            <a href='https://hkust.edu.hk' className="pe-3" target="_blank" rel="noopener noreferrer">
              <img src="/assets/logos/footerlogo.svg" />
            </a>
            <div className='pe-3'>
              <div>
                <a href='https://dataprivacy.ust.hk/university-data-privacy-policy-statement/'
                  className='fw-bold pe-3' target="_blank" rel="noopener noreferrer">Privacy</a>
                {/* <Link to='/' className='fw-bold'>Sitemap</Link> */}
              </div>
              <span>Copyright © The Hong Kong University of Science and Technology. </span><br />
              <span>All rights reserved.</span>
            </div>
            <div style={{ minWidth: '206px', flexBasis: 'min-content' }}>
              <span>Follow HKUST on</span>
              <Nav className="justify-content-end pe-3" style={{ float: 'left', minWidth: '226px' }}>
                <Nav.Link href='https://www.facebook.com/hkust' target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', width: '30px', height: '30px', margin: '0 5px', overflow: 'hidden', position: 'relative' }}>
                  <img src='/assets/icons/fb-logo.svg' className='p-0' style={{ position: 'absolute', top: '0', left: '0', width: 'inherit', height: 'inherit' }} />
                </Nav.Link>
                <Nav.Link href='https://www.linkedin.com/school/hkust' target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', width: '30px', height: '30px', margin: '0 5px', overflow: 'hidden', position: 'relative' }}>
                  <img src='/assets/icons/li-logo.svg' style={{ position: 'absolute', top: '0', left: '0', width: 'inherit', height: 'inherit' }} />
                </Nav.Link>
                <Nav.Link href='https://www.instagram.com/hkust/' target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', width: '30px', height: '30px', margin: '0 5px', overflow: 'hidden', position: 'relative' }}>
                  <img src='/assets/icons/ig-logo.svg' style={{ position: 'absolute', top: '0', left: '0', width: 'inherit', height: 'inherit' }} />
                </Nav.Link>
                <Nav.Link href='https://www.youtube.com/user/HKUST' target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', width: '30px', height: '30px', margin: '0 5px', overflow: 'hidden', position: 'relative' }}>
                  <img src='/assets/icons/yt-logo.svg' style={{ position: 'absolute', top: '0', left: '0', width: 'inherit', height: 'inherit' }} />
                </Nav.Link>
                <Nav.Link href='https://hkust.edu.hk/sites/default/files/menu_icons/mainsite_Wechat_Eng_new.jpg' target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', width: '30px', height: '30px', margin: '0 5px', overflow: 'hidden', position: 'relative' }}>
                  <img src='/assets/icons/wechat-logo.png' style={{ position: 'absolute', top: '0', left: '0', width: 'inherit', height: 'inherit' }} />
                </Nav.Link>
              </Nav>
            </div>
          </div>
        </Container>
      </Tablet>
      <Mobile>
        <Container fluid className='footer' style={{ height: '250px' }}>
          <Container className='footerContainer' style={{ height: '250px', flexDirection: 'column', padding: '20px' }}>
            <div className='justify-content-center' style={{ fontSize: '16px', marginBottom: '10px' }}>
              <a href='https://dataprivacy.ust.hk/university-data-privacy-policy-statement/'
                className='fw-bold pe-3' target="_blank" rel="noopener noreferrer">Privacy</a>
              {/* <Link to='/' className='fw-bold'>Sitemap</Link> */}
            </div>
            <span>Follow HKUST on</span>
            <Nav className="justify-content-end pe-3" style={{ float: 'left', minWidth: '226px' }}>
              <Nav.Link href='https://www.facebook.com/hkust' target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', width: '30px', height: '30px', margin: '0 5px', overflow: 'hidden', position: 'relative' }}>
                <img src='/assets/icons/fb-logo.svg' className='p-0' style={{ position: 'absolute', top: '0', left: '0', width: 'inherit', height: 'inherit' }} />
              </Nav.Link>
              <Nav.Link href='https://www.linkedin.com/school/hkust' target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', width: '30px', height: '30px', margin: '0 5px', overflow: 'hidden', position: 'relative' }}>
                <img src='/assets/icons/li-logo.svg' style={{ position: 'absolute', top: '0', left: '0', width: 'inherit', height: 'inherit' }} />
              </Nav.Link>
              <Nav.Link href='https://www.instagram.com/hkust/' target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', width: '30px', height: '30px', margin: '0 5px', overflow: 'hidden', position: 'relative' }}>
                <img src='/assets/icons/ig-logo.svg' style={{ position: 'absolute', top: '0', left: '0', width: 'inherit', height: 'inherit' }} />
              </Nav.Link>
              <Nav.Link href='https://www.youtube.com/user/HKUST' target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', width: '30px', height: '30px', margin: '0 5px', overflow: 'hidden', position: 'relative' }}>
                <img src='/assets/icons/yt-logo.svg' style={{ position: 'absolute', top: '0', left: '0', width: 'inherit', height: 'inherit' }} />
              </Nav.Link>
              <Nav.Link href='https://hkust.edu.hk/sites/default/files/menu_icons/mainsite_Wechat_Eng_new.jpg' target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', width: '30px', height: '30px', margin: '0 5px', overflow: 'hidden', position: 'relative' }}>
                <img src='/assets/icons/wechat-logo.png' style={{ position: 'absolute', top: '0', left: '0', width: 'inherit', height: 'inherit' }} />
              </Nav.Link>
            </Nav>

            <a href='https://hkust.edu.hk' target="_blank" rel="noopener noreferrer">
              <img src="/assets/logos/footerlogo.svg" />
            </a>

            <span style={{ textAlign: 'center', fontSize: '9px' }}>Copyright © The Hong Kong University of Science and Technology. </span>
            <span style={{ fontSize: '9px' }}>All rights reserved.</span>

          </Container>
        </Container>
      </Mobile>

    </>
  )
}