import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import Container from 'react-bootstrap/Container';
import { Desktop, Tablet, Mobile, Default } from '../components/MediaQuery'
import useCollapse from 'react-collapsed';
import HTMLReactParser from 'html-react-parser';


const PAGE = gql`
query getPageContent {
  page(id:13) {
  data {
  id,
  attributes {
  page_id,
  content
  }
  }
  }
}
`

function nameCard(speaker) {
  return (
    <div key={speaker.id} style={{ display: 'flex', minWidth: '330px', width: '49%', paddingBottom: '20px' }}>
      <div style={{ display: 'flex' }}>
        <img src={`/assets/people/${speaker.id}.jpg`}
          style={{ alignSelf: 'center', paddingRight: '10px', maxWidth: '150px' }} />
      </div>
      <div style={{ alignSelf: 'center', paddingTop: '10px', paddingLeft: '10px' }}>
        <span style={{ display: 'block', fontSize: '18px', fontWeight: 700, marginBottom: '10px' }}>{speaker.name}</span>
        <span style={{ display: 'block', fontSize: '12px' }}>{HTMLReactParser(speaker.title)}</span>
        <span style={{ display: 'block', fontSize: '12px' }}>{HTMLReactParser(speaker.organization)}</span>
      </div>
    </div >
  )
}


export default function OurPeople() {
  const { loading, error, data } = useQuery(PAGE)

  if (loading) return
  if (error) return

  const CONTENT = data.page.data.attributes.content
  console.log(CONTENT)

  return (
    <div>

      <Container className='contentWrap' style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
        <span className='breadcrumbs' style={{ color: '#003366' }}><Link to='/' style={{ textDecoration: 'none', color: '#003366' }}>Home</Link> &#62; About &#62; <b>Our People</b></span>
        <section className="judges pt-5 pb-5" style={{
          display: 'flex',
          flexDirection: 'column',
          borderBottom: 'solid 1px #e5e5e5'
        }}>
          {/* JUDGES */}
          <h2 style={{ color: '#003366', textAlign: 'center', fontWeight: 700, marginBottom: '50px' }}>{CONTENT.judges.title}</h2>
          {(CONTENT.judges.people.length == 0) ? <span style={{ textAlign: 'center' }}>Watch this space for further details.</span> : null}
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: '70px', gap: '10px' }}>
            {CONTENT.judges.people.map((speaker) => (
              nameCard(speaker)
            ))}
          </div>
          {/* ADVISORS */}
          <h2 style={{ color: '#003366', textAlign: 'center', fontWeight: 700, marginBottom: '50px' }}>{CONTENT.advisors.title}</h2>
          {(CONTENT.advisors.people.length == 0) ? <span style={{ textAlign: 'center' }}>Watch this space for further details.</span> : null}
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: '70px', gap: '10px' }}>
            {CONTENT.advisors.people.map((speaker) => (
              nameCard(speaker)
            ))}
          </div>
          {/* MENTORS AND FACILITATORS */}
          <h2 style={{ color: '#003366', textAlign: 'center', fontWeight: 700, marginBottom: '50px' }}>{CONTENT.mentors.title}</h2>
          {(CONTENT.mentors.people.length == 0) ? <span style={{ textAlign: 'center' }}>Watch this space for further details.</span> : null}
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: '70px', gap: '10px' }}>
            {CONTENT.mentors.people.map((speaker) => (
              nameCard(speaker)
            ))}
          </div>
          {/* PROGRAM TEAM */}
          <h2 style={{ color: '#003366', textAlign: 'center', fontWeight: 700, marginBottom: '50px' }}>{CONTENT.programTeam.title}</h2>
          {(CONTENT.programTeam.people.length == 0) ? <span style={{ textAlign: 'center' }}>Watch this space for further details.</span> : null}
          <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: '70px', gap: '10px' }}>
            {CONTENT.programTeam.people.map((speaker) => (
              nameCard(speaker)
            ))}
          </div>
        </section>
      </Container>
    </div >
  )
}
