import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery, gql } from '@apollo/client';
import Container from 'react-bootstrap/Container';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Table from 'react-bootstrap/Table';
import { Desktop, Tablet, Mobile, Default } from '../components/MediaQuery';
import HTMLReactParser from 'html-react-parser';
import AliceCarousel from 'react-alice-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import groupphoto from '../img/winnerAnnouncement/AC2023/analytics-challenge_2023.jpg';
import scambusters from '../img/winnerAnnouncement/AC2023/scambusters.jpg';
import sentianalyst from '../img/winnerAnnouncement/AC2023/sentianalyst.jpg';
import socialpulse from '../img/winnerAnnouncement/AC2023/socialpulse.jpg';
import therisknavigators from '../img/winnerAnnouncement/AC2023/therisknavigators.jpg';
import xinovel from '../img/winnerAnnouncement/AC2023/xinovel.jpg';
import images from '../img/carousel/ac2023/index';

const handleDragStart = (e) => e.preventDefault();

const winnerMap = { 'scambusters': scambusters, 'sentianalyst': sentianalyst, 'socialpulse': socialpulse, 'therisknavigators': therisknavigators, 'xinovel': xinovel }

const carouselItems = [];
for (let i = 0; i < images.length; i++) {
  carouselItems.push(
    <LazyLoadImage className="carousel_img" src={images[i]} onDragStart={handleDragStart} role="presentation" effect="blur" />
  );
}

const Gallery = () => {
  return (
    <div style={{ marginTop: '70px' }}>
      <AliceCarousel
        disableSlideInfo={false}
        mouseTracking items={carouselItems}
        autoPlay={true}
        autoPlayInterval={3000}
        infinite={true}
        autoPlayControls={true}
        responsive={{
          0: {
            items: 2,
            itemsFit: 'contain'
          },
          767: {
            items: 3,
            itemsFit: 'contain'
          }
        }}
      />
    </div>

  );
}

const PAGE = gql`
query getPageContent {
  page(id:18) {
    data {
      id,
      attributes {
        page_id,
        content 
      }
    }
  }
  about: page(id:12) {
    data {
      id,
      attributes {
        page_id,
        content 
      }
    }
  }
}
`

export default function Home() {
  const { loading, error, data } = useQuery(PAGE)

  if (loading) return
  if (error) return

  const CONTENT = data.page.data.attributes.content
  const SUPPORT = data.about.data.attributes.content.support.content
  console.log(SUPPORT)

  return (
    <div>
      <Desktop>
        <section className="hero" style={{ position: 'relative' }}>
          <img src="/assets/images/hero1.jpg" style={{ width: '100vw', height: '450px' }} />
          <Container style={{
            maxWidth: '1200px',
            position: 'absolute',
            top: '35px',
            bottom: '0',
            left: '0',
            right: '0',
            justifyContent: 'space-between',
            textAlign: 'center',
            paddingBottom: '35px'
          }}>
            <img src="/assets/images/hero_overlay.png" style={{ width: 'auto', height: '100%' }} />
            <img src="/assets/images/theme.png" style={{ width: 'auto', height: '100%' }}></img>
          </Container>
          <Container style={{
            maxWidth: '1200px',
            position: 'absolute',
            top: '50px',
            bottom: '0',
            left: '0',
            right: '0',
            display: 'block',

          }}>
            <a href="https://cbsa.hkust.edu.hk" target="_blank" rel="noopener noreferrer">
              <img src="/assets/logos/logo-cbsa_white.png" style={{ width: '250px', padding: '5px' }}></img>
            </a>
            <a href="https://www.wisers.com" target="_blank" rel="noopener noreferrer">
              <img src="/assets/logos/logo-wisers_white.png" style={{ width: '250px', padding: '5px' }}></img>
            </a>

          </Container>
        </section>

        <section className="winnerAnnouncement pt-5 pb-5">
          <Container style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: '14px', color: '#9f1414', fontWeight: 700 }}>Announcement!</span>
            <span style={{ color: '#003366', fontSize: '38px', fontWeight: '700', textAlign: 'left' }}>{CONTENT.winnerAnnouncement.title}</span>
            <p style={{ marginBottom: '20px' }}>{CONTENT.winnerAnnouncement.postingdate}</p>
            <p style={{ marginBottom: '20px' }}>{CONTENT.winnerAnnouncement.content.intro}</p>
            <LazyLoadImage src={groupphoto} style={{ marginBottom: '20px', width: "100%" }} alt="Analytics Challenge group photo" effect="blur" />
            <p style={{ width: '100%' }}>{HTMLReactParser(CONTENT.winnerAnnouncement.content.intro2)}</p>


            <div style={{ display: 'flex', gap: '50px', margin: '30px 0' }}>
              <div style={{ width: '100%' }}>
                <span style={{ fontSize: '25px' }}><b>{CONTENT.winnerAnnouncement.content.winners.title}</b></span>
                {CONTENT.winnerAnnouncement.content.winners.list.map((winner, i) => (
                  <div key={i} style={{ display: 'flex', flexDirection: 'row', margin: '10px 0 30px', gap: '10px' }} >
                    <div style={{ width: '30%' }}>
                      <LazyLoadImage
                        effect="blur"
                        alt="Analytics Challenge winners and finalists photos"
                        src={winnerMap[winner.teamid]}
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div style={{ padding: '0 10px', width: '70%' }}>
                      {winner.details.map((detail, idx) => (
                        <div key={idx}>
                          <span >{HTMLReactParser(detail)}</span><br />
                        </div>
                      ))}
                    </div>


                  </div>
                ))}
              </div>
            </div>

          </Container>
        </section>
        <section className="carousel pt-5 pb-5" style={{ backgroundColor: '#e5e5e542' }}>
          <Container style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ color: '#003366', fontSize: '38px', fontWeight: '700', textAlign: 'left' }}>{CONTENT.carousel.title}</span>
              <span style={{ width: '45%', alignSelf: 'center' }}>{CONTENT.carousel.content}</span>
            </div>
            <div style={{}}>
              <Gallery />
            </div>
          </Container>
        </section>
        <section className="intro1 pt-5 pb-5">
          <Container style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
            <span style={{ color: '#003366', fontSize: '38px', fontWeight: '700', textAlign: 'center', marginBottom: '20px' }}>{CONTENT.intro1.title}</span>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ backgroundColor: '#e5e5e542', width: '56%', padding: '40px 25px' }}>
                <h5>{CONTENT.intro1.content.contentTitle}</h5>
                <br />
                {CONTENT.intro1.content.contentBody.map((content, i) => (
                  <p key={i}>{content}</p>
                ))}
              </div>
              <div style={{ width: '40%', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                <a href="https://cbsa.hkust.edu.hk" style={{ alignSelf: "center", width: '100%' }} target="_blank" rel="noopener noreferrer">
                  <img src={`/assets/logos/logo-cbsa.png`} style={{ width: 'inherit' }} />
                </a>
                <a href='https://www.wisers.com' style={{ alignSelf: "center", width: '100%' }} target="_blank" rel="noopener noreferrer">
                  <img src={`/assets/logos/logo-wisers.png`} style={{ width: 'inherit' }} />
                </a>
              </div>
            </div>
          </Container>
        </section>

        <section className="intro2" style={{ position: 'relative', textAlign: 'center' }}>
          <img src="/assets/images/intro2.jpg" />
          <Container className='contentWrap' style={{
            maxWidth: '1200px',
            position: 'absolute',
            top: '50px',
            bottom: '0',
            left: '0',
            right: '0',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <span style={{ color: 'white', fontSize: '26px', fontWeight: '700' }}>{CONTENT.intro2.title}</span>
            <br />
            <div style={{ maxWidth: '1200px' }}>
              {CONTENT.intro2.content.map((content, i) => (
                <p key={i} style={{ color: 'white' }}>{HTMLReactParser(content)}</p>
              ))}
            </div>
          </Container>
        </section>

        <section className="participation pt-5 pb-5" style={{ backgroundColor: '#e5e5e542' }}>
          <Container style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
            <span style={{ color: '#003366', fontSize: '38px', fontWeight: '700', textAlign: 'center', marginBottom: '20px' }}>
              {CONTENT.participation.title}
            </span>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <div style={{ width: '53%', position: 'relative' }}>
                {CONTENT.participation.content.map((content, i) => (
                  <p key={i}>{HTMLReactParser(content)}</p>
                ))}
                <Link to='/Participation-Agreement' style={{ textDecoration: 'none' }}>
                  <span style={{
                    color: '#003366', border: 'solid 1px #003366', padding: "3px 7px"
                  }}>{CONTENT.participation.button}</span>
                </Link>
              </div>
              <div style={{ width: '43%' }}>
                <img src='/assets/images/analytics-challenge_2022.jpg' style={{ width: '100%', marginBottom: '10px' }} />
                <p style={{ fontSize: '14px' }}><i>{CONTENT.participation.imgDescription}</i></p>
              </div>
            </div>
          </Container>
        </section>

        <section className="resources pt-5 pb-5">
          <Container style={{ maxWidth: '1200px', display: 'flex', justifyContent: 'space-between' }}>
            <img src="/assets/people/prof_allen_huang.jpg" style={{ width: '35%' }} />
            <div style={{ width: '60%' }}>
              <span style={{
                color: '#003366', fontSize: '38px',
                fontWeight: '700', textAlign: 'center', marginBottom: '20px'
              }}>
                {CONTENT.resources.title}
              </span>
              <br />
              <br />
              <span style={{ fontSize: '24px' }}>{CONTENT.resources.content[0]}</span>
              <br />
              <br />
              <Link to='/Resources' style={{ textDecoration: 'none' }}>
                <span style={{
                  color: '#003366', border: 'solid 1px #003366', padding: "3px 7px"
                }}>{CONTENT.resources.button}</span>
              </Link>
            </div>
          </Container>
        </section>

        <section className="dates pt-5 pb-5" style={{ backgroundColor: '#e5e5e542' }}>
          <Container style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
            <span style={{
              color: '#003366', fontSize: '38px',
              fontWeight: '700', textAlign: 'center', marginBottom: '20px'
            }}>
              {CONTENT.dates.title}
            </span>
            <Table bordered>
              <thead>
                <tr style={{ backgroundColor: '#e5e5e5' }}>
                  {CONTENT.dates.table[0].row.map((cell, cellIndex) => (
                    <th key={cellIndex}>{HTMLReactParser(cell)}</th>))}
                </tr>
              </thead>
              <tbody>
                {CONTENT.dates.table.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.row.map((cell, cellIndex) => (
                      <td key={cellIndex}>{HTMLReactParser(cell)}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
            <Link to='/Registration' style={{ textDecoration: 'none', alignSelf: 'center' }}>
              <span style={{
                color: '#003366', border: 'solid 1px #003366', padding: "3px 7px"
              }}>{CONTENT.dates.button}</span>
            </Link>
          </Container>
        </section>

        <section className="support pt-5 pb-5">
          <Container style={{ maxWidth: '1200px', display: 'flex', width: '80%', alignSelf: 'center', flexDirection: 'column' }}>
            <span style={{
              fontSize: '30px',
              fontWeight: '700', textAlign: 'center', marginBottom: '20px'
            }}>
              {CONTENT.support.title}
            </span>
            <div style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
              {SUPPORT.map((organization, i) => (
                <a key={i} href={organization.url} target="_blank" rel="noopener noreferrer">
                  <img src={`/assets/logos/support-${organization.id}.png`}
                    style={{ alignSelf: 'center', padding: '20px', maxWidth: '400px', minWidth: '200px', maxHeight: '140px' }} />                </a>
              ))}
            </div>
          </Container>
        </section>

        <section className="contact" style={{ position: 'relative', textAlign: 'center' }}>
          <img src="/assets/images/contact.jpg" style={{ width: '100vw', height: '395px' }} />
          <Container style={{
            maxWidth: '1200px',
            position: 'absolute',
            paddingTop: '30px',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <div style={{ backgroundColor: 'rgba(255,255,255,0.85', width: '450px', height: '330px', padding: '20px 30px' }}>
              <span style={{
                fontSize: '38px', color: '#003366',
                fontWeight: '500', textAlign: 'center', marginBottom: '20px'
              }}>
                {CONTENT.contact.title}
              </span>
              <br />
              <br />
              <span>{CONTENT.contact.content[0]}</span>
              <br />
              <span>{CONTENT.contact.content[1]}</span>
              <br />
              <span>{CONTENT.contact.content[2]}</span>
              <br />
              <br />
              <span><a href={CONTENT.contact.content[3]} target="_blank" rel="noopener noreferrer">{CONTENT.contact.content[3]}</a></span>
              <br />
              <br />
              <span>{CONTENT.contact.content[4]}</span>
              <br />
              <span><a href={`mailto:${CONTENT.contact.content[5]}`}>{CONTENT.contact.content[5]}</a></span>
            </div>
          </Container>
        </section>
        <Container className='contentWrap' />
      </Desktop>

      <Tablet>
        <section className="hero" style={{ position: 'relative' }}>
          <img src="/assets/images/hero1.jpg" style={{ width: '100vw', height: '400px' }} />

          <Container style={{
            maxWidth: '1200px',
            position: 'absolute',
            top: '35px',
            bottom: '0',
            left: '0',
            right: '0',
            justifyContent: 'space-between',
            textAlign: 'center',
            paddingBottom: '35px',
            overflow: 'hidden'
          }}>
            <img src="/assets/images/hero_overlay.png" style={{ width: 'auto', height: '100%' }} />
            <img src="/assets/images/theme.png" style={{ width: 'auto', height: '100%', paddingLeft: '50px' }}></img>
          </Container>
          <Container style={{
            maxWidth: '1200px',
            position: 'absolute',
            top: '50px',
            bottom: '0',
            left: '0',
            right: '0',
            display: 'block',

          }}>
            <a href="https://cbsa.hkust.edu.hk" target="_blank" rel="noopener noreferrer">
              <img src="/assets/logos/logo-cbsa_white.png" style={{ width: '250px', padding: '5px' }}></img>
            </a>
            <a href="https://www.wisers.com" target="_blank" rel="noopener noreferrer">
              <img src="/assets/logos/logo-wisers_white.png" style={{ width: '250px', padding: '5px' }}></img>
            </a>

          </Container>
        </section>

        <section className="winnerAnnouncement pt-5 pb-5">
          <Container style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: '14px', color: '#9f1414', fontWeight: 700 }}>Announcement!</span>
            <span style={{ color: '#003366', fontSize: '38px', fontWeight: '700', textAlign: 'left' }}>{CONTENT.winnerAnnouncement.title}</span>
            <p style={{ marginBottom: '20px' }}>{CONTENT.winnerAnnouncement.postingdate}</p>
            <p style={{ marginBottom: '20px' }}>{CONTENT.winnerAnnouncement.content.intro}</p>
            <LazyLoadImage src={groupphoto} style={{ marginBottom: '20px', width: "100%" }} alt="Analytics Challenge group photo" effect="blur" />
            <p style={{ width: '100%' }}>{HTMLReactParser(CONTENT.winnerAnnouncement.content.intro2)}</p>


            <div style={{ display: 'flex', gap: '50px', margin: '30px 0' }}>
              <div style={{ width: '100%' }}>
                <span style={{ fontSize: '25px' }}><b>{CONTENT.winnerAnnouncement.content.winners.title}</b></span>
                {CONTENT.winnerAnnouncement.content.winners.list.map((winner, i) => (
                  <div key={i} style={{ display: 'flex', flexDirection: 'row', margin: '10px 0 30px', gap: '10px' }} >
                    <div style={{ width: '30%' }}>
                      <LazyLoadImage
                        effect="blur"
                        alt="Analytics Challenge winners and finalists photos"
                        src={winnerMap[winner.teamid]}
                        style={{ width: '100%' }}
                      />
                    </div>
                    <div style={{ padding: '0 10px', width: '70%' }}>
                      {winner.details.map((detail, idx) => (
                        <div key={idx}>
                          <span >{HTMLReactParser(detail)}</span><br />
                        </div>
                      ))}
                    </div>


                  </div>
                ))}
              </div>
            </div>

          </Container>
        </section>

        <section className="carousel pt-5 pb-5" style={{ backgroundColor: '#e5e5e542' }}>
          <Container style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span style={{ color: '#003366', fontSize: '38px', fontWeight: '700', textAlign: 'left' }}>{CONTENT.carousel.title}</span>
              <span style={{ width: '45%', alignSelf: 'center' }}>{CONTENT.carousel.content}</span>

            </div>
            <div style={{}}>
              <Gallery />
            </div>

          </Container>
        </section>

        <section className="intro1 pt-5 pb-5">
          <Container style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column' }}>
            <span style={{ color: '#003366', fontSize: '38px', fontWeight: '700', textAlign: 'center', marginBottom: '20px' }}>{CONTENT.intro1.title}</span>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ backgroundColor: '#e5e5e542', width: '100%', padding: '40px 25px' }}>
                <h5>{CONTENT.intro1.content.contentTitle}</h5>
                <br />
                {CONTENT.intro1.content.contentBody.map((content, i) => (
                  <p key={i}>{content}</p>
                ))}
              </div>

            </div>
          </Container>
        </section>

        <section className="intro2" style={{ position: 'relative', textAlign: 'center' }}>
          <img src="/assets/images/intro2.jpg" />
          <Container className='contentWrap' style={{
            maxWidth: '1200px',
            position: 'absolute',
            top: '40px',
            bottom: '0',
            left: '0',
            right: '0',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <span style={{ color: 'white', fontSize: '26px', fontWeight: '700' }}>{CONTENT.intro2.title}</span>
            <br />
            <div style={{ maxWidth: '800px' }}>
              {CONTENT.intro2.content.map((content, i) => (
                <p key={i} style={{ color: 'white' }}>{HTMLReactParser(content)}</p>
              ))}
            </div>
          </Container>
        </section>

        <section className="participation pt-5 pb-5" style={{ backgroundColor: '#e5e5e542' }}>
          <Container style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column' }}>
            <span style={{
              color: '#003366', fontSize: '26px', fontWeight: '700',
              textAlign: 'center', marginBottom: '20px'
            }}>
              {CONTENT.participation.title}
            </span>
            <div style={{ width: '100%', paddingBottom: '20px' }}>
              <img src='/assets/images/analytics-challenge_2022.jpg' style={{ width: '100%', marginBottom: '10px' }} />
              <p style={{ fontSize: '9px' }}><i>{CONTENT.participation.imgDescription}</i></p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <div className="pb-3" style={{}}>
                {CONTENT.participation.content.map((content, i) => (
                  <p key={i}>{HTMLReactParser(content)}</p>
                ))}
                <Link to='/Participation-Agreement' style={{ textDecoration: 'none' }}>
                  <span className="mt-xs" style={{
                    color: '#003366', border: 'solid 1px #003366', padding: "3px 7px"
                  }}>{CONTENT.participation.button}</span>
                </Link>
              </div>
            </div>
          </Container>
        </section>

        <section className="resources pt-5 pb-5">
          <Container style={{ maxWidth: '100%', display: 'flex', justifyContent: 'space-between' }}>
            <img src="/assets/people/prof_allen_huang.jpg" style={{ width: '35%' }} />
            <div style={{ width: '60%' }}>
              <span style={{
                color: '#003366', fontSize: '38px',
                fontWeight: '700', textAlign: 'center', marginBottom: '20px'
              }}>
                {CONTENT.resources.title}
              </span>
              <br />
              <br />
              <span style={{ fontSize: '24px' }}>{CONTENT.resources.content[0]}</span>
              <br />
              <br />
              <Link to='/Resources' style={{ textDecoration: 'none' }}>
                <span style={{
                  color: '#003366', border: 'solid 1px #003366', padding: "3px 7px"
                }}>{CONTENT.resources.button}</span>
              </Link>
            </div>
          </Container>
        </section>

        <section className="dates pt-5 pb-5" style={{ backgroundColor: '#e5e5e542' }}>
          <Container style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column' }}>
            <span style={{
              color: '#003366', fontSize: '38px',
              fontWeight: '700', textAlign: 'center', marginBottom: '20px'
            }}>
              {CONTENT.dates.title}
            </span>
            <Table bordered>
              <thead>
                <tr style={{ backgroundColor: '#e5e5e5' }}>
                  {CONTENT.dates.table[0].row.map((cell, cellIndex) => (
                    <th key={cellIndex}>{HTMLReactParser(cell)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {CONTENT.dates.table.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.row.map((cell, cellIndex) => (
                      <td key={cellIndex}>{HTMLReactParser(cell)}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </section>

        <section className="support pt-5 pb-5">
          <Container style={{ maxWidth: '100%', display: 'flex', flex: '1 0 34%', flexDirection: 'column' }}>
            <span style={{
              fontSize: '30px',
              fontWeight: '700', textAlign: 'center', marginBottom: '20px'
            }}>
              {CONTENT.support.title}
            </span>
            <div style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
              {SUPPORT.map((organization, i) => (
                <a key={i} href={organization.url} target="_blank" rel="noopener noreferrer">
                  <img src={`/assets/logos/support-${organization.id}.png`}
                    style={{ alignSelf: 'center', padding: '30px', maxWidth: '400px', maxHeight: '140px' }} />
                </a>
              ))}
            </div>
          </Container>
        </section>

        <section className="contact" style={{ position: 'relative', textAlign: 'center' }}>
          <img src="/assets/images/contact.jpg" style={{ width: '100vw', height: '395px' }} />
          <Container style={{
            position: 'absolute',
            paddingTop: '30px',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <div style={{ backgroundColor: 'rgba(255,255,255,0.85', width: '450px', height: '330px', padding: '20px 30px' }}>
              <span style={{
                fontSize: '38px', color: '#003366',
                fontWeight: '500', textAlign: 'center', marginBottom: '20px'
              }}>
                {CONTENT.contact.title}
              </span>
              <br />
              <br />
              <span>{CONTENT.contact.content[0]}</span>
              <br />
              <span>{CONTENT.contact.content[1]}</span>
              <br />
              <span>{CONTENT.contact.content[2]}</span>
              <br />
              <br />
              <span>{CONTENT.contact.content[3]}</span>
              <br />
              <br />
              <span>{CONTENT.contact.content[4]}</span>
              <br />
              <span>{CONTENT.contact.content[5]}</span>
            </div>
          </Container>
        </section>
        <Container className='contentWrap' />
      </Tablet>

      <Mobile>
        <section className="hero" style={{ position: 'relative' }}>
          <img src="/assets/images/hero1_mobile.jpg" style={{ width: '100vw', height: '400px' }} />
          <Container style={{
            maxWidth: '1200px',
            position: 'absolute',
            top: '20px',
            bottom: '0',
            left: '0',
            right: '0',
            justifyContent: 'space-between',

            textAlign: 'center',
            paddingBottom: '20px',
            overflow: 'hidden'
          }}>
            <img src="/assets/images/hero_overlay_mobile.png" style={{ width: 'auto', height: '350px' }} />
            <img src="/assets/images/theme.png" style={{ width: 'auto', height: '350px', paddingBottom: '120px', marginLeft: '-55px' }}></img>
          </Container>
          <Container style={{
            maxWidth: '1200px',
            position: 'absolute',
            top: '45px',
            bottom: '0',
            left: '0',
            right: '0',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <a href="https://cbsa.hkust.edu.hk" target="_blank" rel="noopener noreferrer">
              <img src="/assets/logos/logo-cbsa_white.png" style={{ width: '150px' }}></img>
            </a>
            <a href="https://www.wisers.com" target="_blank" rel="noopener noreferrer">
              <img src="/assets/logos/logo-wisers_white.png" style={{ width: '150px' }}></img>
            </a>
          </Container>
        </section>


        <section className="winnerAnnouncement pt-3 pb-3">
          <Container style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: '14px', color: '#9f1414', fontWeight: 700 }}>Announcement!</span>
            <span style={{ color: '#003366', fontSize: '22px', fontWeight: '700', textAlign: 'left' }}>{CONTENT.winnerAnnouncement.title}</span>
            <p style={{ marginBottom: '20px' }}>{CONTENT.winnerAnnouncement.postingdate}</p>
            <p style={{ marginBottom: '20px' }}>{CONTENT.winnerAnnouncement.content.intro}</p>
            <LazyLoadImage src={groupphoto} style={{ marginBottom: '20px', width: "100%" }} alt="Analytics Challenge group photo" effect="blur" />
            <p style={{ width: '100%' }}>{HTMLReactParser(CONTENT.winnerAnnouncement.content.intro2)}</p>

            <div style={{ width: '100%' }}>
              <span style={{ fontSize: '18px' }}><b><u>Winners</u></b></span>
              {CONTENT.winnerAnnouncement.content.winners.list.map((winner, i) => (
                <div key={i} style={{ display: 'flex', flexDirection: 'row', margin: '10px 0 30px', gap: '10px' }} >
                  <div style={{ width: '100%' }}>
                    {winner.details.slice(0, 2).map((detail, idx) => (
                      <div key={idx}>
                        <span >{HTMLReactParser(detail)}</span><br />
                      </div>
                    ))}
                    <LazyLoadImage
                      effect="blur"
                      alt="Analytics Challenge winners and finalists photos"
                      src={winnerMap[winner.teamid]}
                      style={{ width: '100%', margin: '20px 0' }}
                    />
                    {winner.details.slice(2).map((detail, idx) => (
                      <div key={idx}>
                        <span >{HTMLReactParser(detail)}</span><br />
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </Container>
        </section>

        <section className="carousel pt-3 pb-3" style={{ backgroundColor: '#e5e5e542' }}>
          <Container style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span style={{ color: '#003366', fontSize: '22px', fontWeight: '700', textAlign: 'left' }}>{CONTENT.carousel.title}</span>
              <span style={{ alignSelf: 'center', fontSize: '14px' }}>{CONTENT.carousel.content}</span>

            </div>
            <Gallery />

          </Container>
        </section>

        <section className="intro1 pt-3 pb-3">
          <Container style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column' }}>
            <span style={{ color: '#003366', fontSize: '22px', fontWeight: '700', textAlign: 'center', marginBottom: '20px' }}>{CONTENT.intro1.title}</span>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
              <div style={{ backgroundColor: '#e5e5e542', padding: '40px 25px' }}>
                <h5><b>{CONTENT.intro1.content.contentTitle}</b></h5>
                <br />
                {CONTENT.intro1.content.contentBody.map((content, i) => (
                  <ReactMarkdown key={i} children={content} remarkPlugins={[remarkGfm]} />
                ))}
              </div>
            </div>
          </Container>
        </section>

        <section className="intro2" style={{ position: 'relative', textAlign: 'center' }}>
          <img src="/assets/images/intro2_mobile.jpg" />
          <Container className='contentWrap' style={{
            position: 'absolute',
            top: '40px',
            bottom: '0',
            left: '0',
            right: '0',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <span style={{ color: 'white', fontSize: '18px', fontWeight: '700', maxWidth: '85%' }}>{CONTENT.intro2.title}</span>
            <br />
            <div style={{ maxWidth: '800px' }}>
              {CONTENT.intro2.content.map((content, i) => (
                <p key={i} className="mt" style={{ color: 'white' }}>{HTMLReactParser(content)}</p>
              ))}
            </div>
          </Container>
        </section>

        <section className="participation pt-3 pb-3" style={{ backgroundColor: '#e5e5e542' }}>
          <Container style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <span style={{
              color: '#003366', fontSize: '26px', fontWeight: '700',
              textAlign: 'center', marginBottom: '20px', maxWidth: '80%'
            }}>
              {CONTENT.participation.title}
            </span>
            <div style={{ width: '100%', paddingBottom: '20px' }}>
              <img src='/assets/images/analytics-challenge_2022.jpg' style={{ width: '100%', marginBottom: '10px' }} />
              <p style={{ fontSize: '9px' }}><i>{CONTENT.participation.imgDescription}</i></p>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
              <div className="pb-3" style={{}}>
                {CONTENT.participation.content.map((content, i) => (
                  <p key={i}>{HTMLReactParser(content)}</p>
                ))}
                <Link to='/Participation-Agreement' style={{ textDecoration: 'none' }}>
                  <span className="mt-xs" style={{
                    color: '#003366', border: 'solid 1px #003366', padding: "3px 7px"
                  }}>{CONTENT.participation.button}</span>
                </Link>
              </div>
            </div>
          </Container>
        </section>

        <section className="resources" style={{ position: 'relative' }}>
          <img src="/assets/people/prof_allen_huang_mobile.jpg" style={{ width: '100%', maxHeight: '525px' }} />
          <Container className='contentWrap' style={{
            position: 'absolute',
            top: '30px',
            bottom: '0',
            left: '0',
            right: '0',
            flexDirection: 'column'
          }}>
            <div style={{ width: '60%', position: 'absolute' }}>
              <span style={{
                color: '#003366', fontSize: '26px',
                fontWeight: '700'
              }}>
                {CONTENT.resources.title}
              </span>
              <div style={{ backgroundColor: 'rgba(255,255,255,0.35)', lineHeight: '1.2', marginTop: '10px', padding: '10px 15px' }}>
                <span style={{ fontSize: '12px', lineHeight: '1' }}>{CONTENT.resources.content[0]}</span>
                <br />
                <div style={{ marginTop: '10px' }}>
                  <Link className="mt-xs" to='/Resources' style={{ textDecoration: 'none' }}>
                    <span style={{
                      color: '#003366', border: 'solid 1px #003366', padding: "3px 7px"
                    }}>{CONTENT.resources.button}</span>
                  </Link>
                </div>

              </div>

            </div>
          </Container>
        </section>

        <section className="dates pt-3 pb-3" style={{ backgroundColor: '#e5e5e542' }}>
          <Container style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column' }}>
            <span style={{
              color: '#003366', fontSize: '26px',
              fontWeight: '700', textAlign: 'center', marginBottom: '20px'
            }}>
              {CONTENT.dates.title}
            </span>
            <Table bordered>
              <thead>
                <tr className='mt-s' style={{ backgroundColor: '#e5e5e5' }}>
                  {CONTENT.dates.table[0].row.map((cell, cellIndex) => (
                    <th key={cellIndex}>{HTMLReactParser(cell)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {CONTENT.dates.table.slice(1).map((row, rowIndex) => (
                  <tr key={rowIndex} className='mt-s'>
                    {row.row.map((cell, cellIndex) => (
                      <td key={cellIndex}>{HTMLReactParser(cell)}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        </section>

        <section className="support pt-3 pb-3">
          <Container style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column' }}>
            <span style={{
              fontSize: '16px', maxWidth: '95%', alignSelf: 'center',
              fontWeight: '700', textAlign: 'center', marginBottom: '20px'
            }}>
              {CONTENT.support.title}
            </span>
            <div style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
              <a href={SUPPORT[0].url} style={{ width: '45%', }} target="_blank" rel="noopener noreferrer">
                <img src={`/assets/logos/support-${SUPPORT[0].id}.png`}
                  style={{ alignSelf: 'center', padding: '20px 5px', maxWidth: '100%', maxHeight: '150px' }} />
              </a>
              <a href={SUPPORT[1].url} style={{ width: '55%', }} target="_blank" rel="noopener noreferrer">
                <img src={`/assets/logos/support-${SUPPORT[1].id}.png`}
                  style={{ alignSelf: 'center', padding: '20px 5px', maxWidth: '100%', maxHeight: '150px' }} />
              </a>
              <a href={SUPPORT[2].url} style={{ width: '50%', }} target="_blank" rel="noopener noreferrer">
                <img src={`/assets/logos/support-${SUPPORT[2].id}.png`}
                  style={{ alignSelf: 'center', padding: '20px 5px', maxWidth: '100%', maxHeight: '150px' }} />
              </a>
              <a href={SUPPORT[3].url} style={{ width: '100%', }} target="_blank" rel="noopener noreferrer">
                <img src={`/assets/logos/support-${SUPPORT[3].id}.png`}
                  style={{ alignSelf: 'center', padding: '20px 15px', maxWidth: '100%', maxHeight: '150px' }} />
              </a>
            </div>
          </Container>
        </section>

        <section className="contact" style={{ position: 'relative', textAlign: 'center' }}>
          <img src="/assets/images/contact.jpg" style={{ height: '400px', float: 'right' }} />
          <Container style={{
            position: 'absolute',
            top: '0',
            bottom: '0',
            left: '0',
            right: '0',
            alignItems: 'center',
            flexDirection: 'column',
            height: '400px',
            padding: '20px'
          }}>
            <div style={{ backgroundColor: 'rgba(255,255,255,0.85', height: '400px', padding: '10px', margin: '20px 0' }}>
              <span style={{
                fontSize: '26px', color: '#003366',
                fontWeight: '500', textAlign: 'center', marginBottom: '20px'
              }}>
                {CONTENT.contact.title}
              </span>
              <br />
              <br />
              <span>{CONTENT.contact.content[0]}</span>
              <br />
              <span>{CONTENT.contact.content[1]}</span>
              <br />
              <span>{CONTENT.contact.content[2]}</span>
              <br />
              <br />
              <span><a href={CONTENT.contact.content[3]} target="_blank" rel="noopener noreferrer">{CONTENT.contact.content[3]}</a></span>
              <br />
              <br />
              <span>{CONTENT.contact.content[4]}</span>
              <br />
              <span><a href={`mailto:${CONTENT.contact.content[5]}`}>{CONTENT.contact.content[5]}</a></span>
            </div>
          </Container>
        </section>
        <Container className='contentWrap' />
      </Mobile>

    </div >
  )
}
