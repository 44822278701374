import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

// page & layout imports
import Homepage from './pages/Home';
import Navigation from './components/Navigation';
import AboutUs from './pages/AboutUs';
import OurPeople from './pages/OurPeople';
import Sponsorship from './pages/Sponsorship';
import Resources from './pages/Resources';
import WebinarSeries from './pages/WebinarSeries';
import DataTools from './pages/DataTools';
import Registration from './pages/Registration';
import ChallengeBrief from './pages/ChallengeBrief';
import ChallengeBrief2324 from './pages/ChallengeBrief_23-24';
import ParticipationAgreement from './pages/ParticipationAgreement';
import Finalists2022 from './pages/PastFinalists/Finalists2022';
import ParticipationAgreement2324 from './pages/ParticipationAgreement_23-24';
import DatasetAgreement2324 from './pages/DatasetAgreement_23-24';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';


// apollo client
const client = new ApolloClient({
  uri: 'https://admin-analyticschallenge.hkust.edu.hk/graphql',
  cache: new InMemoryCache()
})


function App() {
  return (
    <Router>
      <ApolloProvider client={client}>
        <div className="App">
          <ScrollToTop />
          <Navigation />
          <Routes>
            <Route exact path="/" element={<Homepage />} />
            <Route path="/Registration" element={<Registration />} />
            <Route path="/Sponsorship" element={<Sponsorship />} />
            <Route path="/About/About-Us" element={<AboutUs />} />
            <Route path="/About/Our-People" element={<OurPeople />} />
            <Route path="/Resources" element={<Navigate to="/Resources/Webinar-Series" />} />
            <Route path="/Resources/Webinar-Series" element={<WebinarSeries />} />
            <Route path="/Resources/Data-Tools" element={<DataTools />} />
            <Route path="/Resources/Challenge-Brief" element={<ChallengeBrief />} />
            <Route path="/Resources/Challenge-Brief-2023-2024" element={<ChallengeBrief2324 />} />
            <Route path="/Participation-Agreement" element={<ParticipationAgreement2324 />} />
            <Route path="/Dataset-Agreement" element={<DatasetAgreement2324 />} />
            <Route path="/About/Past-Finalists/2022" element={<Finalists2022 />} />
          </Routes>
          <Footer />
        </div>
      </ApolloProvider>
    </Router>
  );
}

export default App;
