import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import Container from 'react-bootstrap/Container';
import { Desktop, Tablet, Mobile, Default } from '../components/MediaQuery'


const PAGE = gql`
query getPageContent {
  page(id:12) {
  data {
    id,
    attributes {
    page_id,
    content
    }
  }
  }
}
`

export default function About() {
  const { loading, error, data } = useQuery(PAGE)

  if (loading) return
  if (error) return

  const CONTENT = data.page.data.attributes.content
  const SUPPORT = CONTENT.support.content
  // console.log(data)

  return (
    <div>
      <Default>
        <Container className='contentWrap' style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
          <span className='breadcrumbs' style={{ color: '#003366' }}><Link to='/' style={{ textDecoration: 'none', color: '#003366' }}>Home</Link> &#62; About &#62; <b>About Us</b></span>
          <section className="aboutIntro pt-5 pb-5" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            borderBottom: 'solid 1px #e5e5e5'
          }}>
            <h1 style={{ color: '#003366', marginBottom: "30px" }}>{CONTENT.aboutIntro.title}</h1>
            {CONTENT.aboutIntro.content.map((body, i) => (
              <p key={i} style={{ textAlign: 'start' }}>{body}</p>
            ))}
          </section>
          <section className="host pt-5 pb-5">
            {CONTENT.host.map((host, i) => (
              <div key={i} style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between' }}>
                <a href={host.url} style={{ alignSelf: "center", width: '100%', minWidth: '350px' }} target="_blank" rel="noopener noreferrer">
                  <img src={`/assets/logos/logo-${host.name}.png`} style={{ width: 'inherit' }} />
                </a>
                <div className="p-3" style={{ maxWidth: '665px' }}>
                  <h4 style={{ color: '#003366' }}>{host.content.title}</h4>
                  <p >{host.content.content}</p>
                </div>
              </div>

            ))}
          </section>
          {/* 
          <section className="support pt-5 pb-5" style={{ display: 'flex', flexDirection: 'column' }}>
            <h2 style={{ textAlign: 'center', color: '#003366', fontWeight: '700', marginBottom: '50px' }}>{CONTENT.support.title}</h2>
            <div style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
              {CONTENT.support.content.map((organization, i) => (
                <a key={i} href={organization.url} target="_blank" rel="noopener noreferrer">
                  <img src={`/assets/logos/support-${organization.id}.png`}
                    style={{ alignSelf: 'center', padding: '30px', maxWidth: '400px', maxHeight: '140px' }} />
                </a>
              ))}
            </div>
          </section> */}

          <section className="support pt-5 pb-5">
            <Container style={{ maxWidth: '1200px', display: 'flex', width: '80%', alignSelf: 'center', flexDirection: 'column' }}>
              <span style={{
                fontSize: '30px',
                fontWeight: '700', textAlign: 'center', marginBottom: '20px'
              }}>
                {CONTENT.support.title}
              </span>
              <div style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                {SUPPORT.map((organization, i) => (
                  <a key={i} href={organization.url} target="_blank" rel="noopener noreferrer">
                    <img src={`/assets/logos/support-${organization.id}.png`}
                      style={{ alignSelf: 'center', padding: '20px', maxWidth: '400px', minWidth: '200px', maxHeight: '140px' }} />                </a>
                ))}
              </div>
            </Container>
          </section>
        </Container>
      </Default>


      <Mobile>
        <Container className='contentWrap' style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
          <span className='breadcrumbs' style={{ color: '#003366' }}>Home &#62; About &#62; <b>About Us</b></span>
          <section className="aboutIntro pt-3 pb-3" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}>
            <h1 style={{ color: '#003366', marginBottom: "30px" }}>{CONTENT.aboutIntro.title}</h1>
            {CONTENT.aboutIntro.content.map((body, i) => (
              <p key={i} style={{ textAlign: 'start' }}>{body}</p>
            ))}
          </section>

          <section className="host pt-3 pb-3" style={{ borderBottom: 'solid 1px #e5e5e5' }}>
            {CONTENT.host.map((host, i) => (
              <div key={i} className="pb-3 pt-3" style={{ maxWidth: '665px' }}>
                <h4 style={{ color: '#003366' }}>{host.content.title}</h4>
                <a href={host.url} style={{ alignSelf: "center" }} target="_blank" rel="noopener noreferrer">
                  <img style={{ width: '80%', padding: '10px 0 20px 0' }} src={`/assets/logos/logo-${host.name}.png`} />
                </a>
                <p >{host.content.content}</p>
              </div>
            ))}
          </section>
          {/* <section className="support pt-3 pb-3" style={{ display: 'flex', flexDirection: 'column' }}>
            <h1 className="pt-3" style={{ textAlign: 'center', color: '#003366' }}>{CONTENT.support.title}</h1>
            <div style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
              <a href={CONTENT.support.content[0].url} style={{ width: '35%', }} target="_blank" rel="noopener noreferrer">
                <img src={`/assets/logos/support-${CONTENT.support.content[0].id}.png`}
                  style={{ alignSelf: 'center', padding: '20px 5px', maxWidth: '100%' }} />
              </a>
              <a href={CONTENT.support.content[3].url} style={{ width: '65%', }} target="_blank" rel="noopener noreferrer">
                <img src={`/assets/logos/support-${CONTENT.support.content[3].id}.png`}
                  style={{ alignSelf: 'center', padding: '20px 5px', maxWidth: '100%' }} />
              </a>
              <a href={CONTENT.support.content[2].url} style={{ width: '100%', }} target="_blank" rel="noopener noreferrer">
                <img src={`/assets/logos/support-${CONTENT.support.content[2].id}.png`}
                  style={{ alignSelf: 'center', padding: '20px 5px', maxWidth: '100%' }} />
              </a>
              <a href={CONTENT.support.content[1].url} style={{ width: '100%', }} target="_blank" rel="noopener noreferrer">
                <img src={`/assets/logos/support-${CONTENT.support.content[1].id}.png`}
                  style={{ alignSelf: 'center', padding: '20px 15px', maxWidth: '100%' }} />
              </a>
              <a href={CONTENT.support.content[4].url} style={{ width: '50%', }} target="_blank" rel="noopener noreferrer">
                <img src={`/assets/logos/support-${CONTENT.support.content[4].id}.png`}
                  style={{ alignSelf: 'center', padding: '20px 15px', maxWidth: '100%' }} />
              </a>
            </div>
          </section> */}

          <section className="support pt-3 pb-3">
            <Container style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column' }}>
              <span style={{
                fontSize: '26px', maxWidth: '95%', alignSelf: 'center',
                fontWeight: '700', textAlign: 'center', marginBottom: '20px', color: '#003366'
              }}>
                {CONTENT.support.title}
              </span>
              <div style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                <a href={SUPPORT[0].url} style={{ width: '45%', }} target="_blank" rel="noopener noreferrer">
                  <img src={`/assets/logos/support-${SUPPORT[0].id}.png`}
                    style={{ alignSelf: 'center', padding: '20px 5px', maxWidth: '100%', maxHeight: '150px' }} />
                </a>
                <a href={SUPPORT[1].url} style={{ width: '55%', }} target="_blank" rel="noopener noreferrer">
                  <img src={`/assets/logos/support-${SUPPORT[1].id}.png`}
                    style={{ alignSelf: 'center', padding: '20px 5px', maxWidth: '100%', maxHeight: '150px' }} />
                </a>
                <a href={SUPPORT[2].url} style={{ width: '50%', }} target="_blank" rel="noopener noreferrer">
                  <img src={`/assets/logos/support-${SUPPORT[2].id}.png`}
                    style={{ alignSelf: 'center', padding: '20px 5px', maxWidth: '100%', maxHeight: '150px' }} />
                </a>
                <a href={SUPPORT[3].url} style={{ width: '100%', }} target="_blank" rel="noopener noreferrer">
                  <img src={`/assets/logos/support-${SUPPORT[3].id}.png`}
                    style={{ alignSelf: 'center', padding: '20px 15px', maxWidth: '100%', maxHeight: '150px' }} />
                </a>
              </div>
            </Container>
          </section>

        </Container>
      </Mobile>
    </div>
  )
}
