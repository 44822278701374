import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import Container from 'react-bootstrap/Container';
import { Desktop, Tablet, Mobile, Default } from '../components/MediaQuery'
import useCollapse from 'react-collapsed';
import HTMLReactParser from 'html-react-parser';


const PAGE = gql`
query getPageContent {
  page(id:9) {
  data {
    id,
    attributes {
    page_id,
    content
    }
  }
  }
}
`

function Collapsible(props) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  return (
    <div className="collapsible">
      <div className="header" {...getToggleProps()} style={{ color: '#003366', fontSize: props.headerSize, fontWeight: '700' }}>
        {props.header}
      </div>
      <div {...getCollapseProps()}>
        <div className="content" style={{ padding: '20px 10px 30px 10px', margin: '10px 0 20px 0', backgroundColor: '#e5e5e542' }}>
          {props.body.map((row, idx) => (
            <>
              <span key={idx} style={{}}>{HTMLReactParser(row)}</span>
              <br />
            </>
          ))}
        </div>
      </div>
    </div >
  );
}

export default function ChallengeBrief() {
  const { loading, error, data } = useQuery(PAGE)

  if (loading) return
  if (error) return

  const CONTENT = data.page.data.attributes.content
  // console.log(CONTENT.speakers)


  return (
    <div>
      <Container className='contentWrap' style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
        <span className='breadcrumbs' style={{ color: '#003366' }}><Link to='/' style={{ textDecoration: 'none', color: '#003366' }}>Home</Link> &#62; Resources &#62; <b>Challenge Brief</b></span>
        <div className="pt-5" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', borderBottom: '1px solid #e5e5e5', marginBottom: '50px' }}>
          <h1 style={{ color: '#003366', marginBottom: '30px' }}>{CONTENT.challengeBriefIntro.title}</h1>
          {CONTENT.challengeBriefIntro.desc.map((desc, idx) => (
            <p key={idx} style={{ color: '#003366', marginBottom: '30px' }}>{HTMLReactParser(desc)}</p>))}
        </div>
        <div style={{ marginBottom: '100px' }}>
          <span style={{ color: '#aaaaaa' }}><i>Click to expand</i></span>
          <Default>
            {CONTENT.contents.map((content, idx) => (
              <Collapsible key={idx} header={content.title} headerSize='26px' body={content.body} />
            ))}
          </Default>
          <Mobile>
            {CONTENT.contents.map((content, idx) => (
              <Collapsible key={idx} header={content.title} headerSize='18px' body={content.body} />
            ))}
          </Mobile>
        </div>
      </Container>
    </div>
  )
}
