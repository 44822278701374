import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import Container from 'react-bootstrap/Container';
import { Desktop, Tablet, Mobile, Default } from '../components/MediaQuery'


const PAGE = gql`
query getPageContent {
  page(id:14) {
  data {
    id,
    attributes {
    page_id,
    content
    }
  }
  }
}
`

function nameCard(speaker) {
  return (
    <div key={speaker.id} style={{ display: 'flex', width: '100%', borderTop: '1px solid #e5e5e5' }}>
      <div style={{ width: '25%', textAlign: 'end', padding: '70px 40px' }}>
        <h4><b>{speaker.webinar.date[0]}</b></h4>
        <h5>{speaker.webinar.date[1]}</h5>
        <h5>{speaker.webinar.date[2]}</h5>
        <span>{speaker.webinar.date[3]}</span><br />
        <span>
          <a href={speaker.webinar.registration_link} target="_blank" rel="noopener noreferrer">
            <button style={{ color: 'white', backgroundColor: '#003366' }}>{speaker.webinar.registration_button}</button>
          </a>
        </span>
        <br />
        <span style={{ display: speaker.webinar.slides_display, marginTop: '15px' }}><a href={speaker.webinar.slides_link} target="_blank" rel="noopener noreferrer"><button style={{ color: 'white', backgroundColor: '#003366' }}>{speaker.webinar.slides_button}</button></a></span><br />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '75%', backgroundColor: 'rgba(229, 229, 229, 0.3)', padding: '50px 40px 40px', textAlign: 'start' }}>
        <h3 style={{ color: '#003366' }}><b>{speaker.webinar.title}</b></h3>
        <p>{speaker.webinar.content}</p>
        <br />
        <div style={{ display: 'flex', flexDirection: 'row' }} >
          <img src={`/assets/people/${speaker.id}.jpg`} style={{ width: '200px' }} />
          <div style={{ padding: '20px', alignSelf: 'flex-end' }}>
            <h4 className='pt-4 pb-2'>{speaker.name}</h4>
            <span style={{ fontWeight: '300' }}>{speaker.title}</span>
            <br />
            <span style={{ fontWeight: '300' }}>{speaker.organization}</span>
            <br />
            <a href={speaker.url} style={{ textDecoration: 'none', color: 'black' }} target="_blank" rel="noopener noreferrer">
              <span style={{ borderBottom: '1px solid black' }}>{speaker.bio}</span>
            </a>
          </div>
        </div>
      </div>
    </div >
  )
}

function nameCardMobile(speaker) {
  return (
    <div key={speaker.id} style={{ borderTop: '1px solid #e5e5e5', padding: '20px 0' }}>
      <div style={{ textAlign: 'start', padding: '20px 0' }}>
        <h3 style={{ color: '#003366' }}><b>{speaker.webinar.title}</b></h3>
        <span>{speaker.webinar.date[1]}, </span>
        <span>{speaker.webinar.date[0]}, </span>
        <span>{speaker.webinar.date[2]}</span><br />
        <div style={{ display: 'flex' }}>
          <span>
            <a href={speaker.webinar.registration_link} target="_blank" rel="noopener noreferrer">
              <button style={{ color: 'white', backgroundColor: '#003366', border: 'none', margin: '10px 0' }}>{speaker.webinar.registration_button}
              </button>
            </a>
          </span>
          <span style={{ display: speaker.webinar.slides_display, marginLeft: '15px' }}>
            <a href={speaker.webinar.slides_link} target="_blank" rel="noopener noreferrer">
              <button style={{ color: 'white', backgroundColor: '#003366', border: 'none', margin: '10px 0' }}>{speaker.webinar.slides_button}</button>
            </a>
          </span>
          <br />
        </div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'rgba(229, 229, 229, 0.3)', padding: '10px', textAlign: 'start' }}>
        <p style={{ fontSize: '14px' }}>{speaker.webinar.content}</p>
        <div style={{ display: 'flex', flexDirection: 'row' }} >
          <img src={`/assets/people/${speaker.id}.jpg`} style={{ width: '100px', objectFit: 'scale-down' }} />
          <div style={{ paddingLeft: '20px', alignSelf: 'flex-end' }}>
            <span style={{ fontSize: '14px' }}>{speaker.name}</span><br />
            <span style={{ fontWeight: '300', fontSize: '12px' }}>{speaker.title}</span><br />
            <span style={{ fontWeight: '300', fontSize: '12px' }}>{speaker.organization}</span><br />
            <a href={speaker.url} style={{ textDecoration: 'none', color: 'black' }} target="_blank" rel="noopener noreferrer">
              <span style={{ borderBottom: '1px solid black', fontSize: '12px' }}>{speaker.bio}</span>
            </a>
          </div>
        </div>
      </div>
    </div >
  )
}

export default function Homepage() {
  const { loading, error, data } = useQuery(PAGE)

  if (loading) return
  if (error) return

  const CONTENT = data.page.data.attributes.content.webinarSeriesIntro
  // console.log(CONTENT.speakers)

  return (
    <div>
      <Default>
        <Container className='contentWrap' style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
          <span className='breadcrumbs' style={{ color: '#003366' }}><Link to='/' style={{ textDecoration: 'none', color: '#003366' }}>Home</Link> &#62; Resources &#62; <b>Webinar Series</b></span>
          <div className="pt-5" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', borderBottom: '1px solid #e5e5e5', marginBottom: '100px' }}>
            <h1 style={{ color: '#003366', marginBottom: '30px' }}>{CONTENT.title}</h1>
            <p style={{ lineHeight: '1.5' }}>{CONTENT.content}</p>
            <br />
            <div style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center' }}>
              {CONTENT.speakers.map((speaker) => (
                nameCard(speaker)
              ))}
            </div>

          </div>
        </Container>
      </Default>
      <Mobile>
        <Container className='contentWrap' style={{ maxWidth: '1200px', display: 'flex', flexDirection: 'column' }}>
          <span className='breadcrumbs' style={{ color: '#003366' }}><Link to='/' style={{ textDecoration: 'none', color: '#003366' }}>Home</Link> &#62; Resources &#62; <b>Webinar Series</b></span>
          <div className="pt-5" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', borderBottom: '1px solid #e5e5e5', marginBottom: '100px' }}>
            <h1 style={{ color: '#003366', marginBottom: '30px' }}>{CONTENT.title}</h1>
            <p style={{ lineHeight: '1.5' }}>{CONTENT.content}</p>
            <br />
            <div style={{ display: 'flex', flexFlow: 'wrap', justifyContent: 'center' }}>
              {CONTENT.speakers.map((speaker) => (
                nameCardMobile(speaker)
              ))}
            </div>

          </div>
        </Container>
      </Mobile>




    </div>
  )
}
