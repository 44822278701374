import img1AC2022 from "./photos/1.jpg"
import img2AC2022 from "./photos/2.jpg"
import img3AC2022 from "./photos/3.jpg"
import img4AC2022 from "./photos/4.jpg"
import img5AC2022 from "./photos/5.jpg"
import img6AC2022 from "./photos/6.jpg"
import img7AC2022 from "./photos/7.jpg"
import img8AC2022 from "./photos/8.jpg"
import img9AC2022 from "./photos/9.jpg"
import img10AC2022 from "./photos/10.jpg"
import img11AC2022 from "./photos/11.jpg"
import img12AC2022 from "./photos/12.jpg"
import img13AC2022 from "./photos/13.jpg"
import img14AC2022 from "./photos/14.jpg"
import img15AC2022 from "./photos/15.jpg"
import img16AC2022 from "./photos/16.jpg"
import img17AC2022 from "./photos/17.jpg"
import img18AC2022 from "./photos/18.jpg"
import img19AC2022 from "./photos/18.jpg"
import img20AC2022 from "./photos/20.jpg"
import img21AC2022 from "./photos/21.jpg"
import img22AC2022 from "./photos/22.jpg"
import img23AC2022 from "./photos/23.jpg"
import img24AC2022 from "./photos/24.jpg"
import img25AC2022 from "./photos/25.jpg"
import img26AC2022 from "./photos/26.jpg"
import img27AC2022 from "./photos/27.jpg"
import img28AC2022 from "./photos/28.jpg"
import img29AC2022 from "./photos/29.jpg"
import img30AC2022 from "./photos/30.jpg"
import img31AC2022 from "./photos/31.jpg"
import img32AC2022 from "./photos/32.jpg"
import img33AC2022 from "./photos/33.jpg"
import img34AC2022 from "./photos/34.jpg"
import img35AC2022 from "./photos/35.jpg"
import img36AC2022 from "./photos/36.jpg"
import img37AC2022 from "./photos/37.jpg"


export default [
    img1AC2022,
    img2AC2022,
    img3AC2022,
    img4AC2022,
    img5AC2022,
    img6AC2022,
    img7AC2022,
    img8AC2022,
    img9AC2022,
    img10AC2022,
    img11AC2022,
    img12AC2022,
    img13AC2022,
    img14AC2022,
    img15AC2022,
    img16AC2022,
    img17AC2022,
    img18AC2022,
    img19AC2022,
    img20AC2022,
    img21AC2022,
    img22AC2022,
    img23AC2022,
    img24AC2022,
    img25AC2022,
    img26AC2022,
    img27AC2022,
    img28AC2022,
    img29AC2022,
    img30AC2022,
    img31AC2022,
    img32AC2022,
    img33AC2022,
    img34AC2022,
    img35AC2022,
    img36AC2022,
    img37AC2022,
]; 
